import { Injectable } from '@angular/core';
import {TypologyLabel} from '@modules/activities/core/typologies/typology.label';
import {ModelSchema, Structures} from "octopus-model";
import {modulesSettings} from '../../../../settings';
import {get} from "lodash-es";

const settingsStructure: ModelSchema = new ModelSchema({
    activitiesOptions: Structures.object({}),
    activitiesWithDefinedTypology: Structures.array([]),
});

@Injectable({
  providedIn: 'root'
})
export class ActivitiesConfigurationService {

    private privateSettings: Partial<{
        activityOptions: { displayAnswersLabel: boolean } | undefined,
        activitiesWithDefinedTypology: TypologyLabel[]
    }>;

    constructor() {
        this.privateSettings = settingsStructure.filterModel(modulesSettings.activities);
    }

    public activityOptions(typology: 'trueFalse' | 'qcu'): { displayAnswersLabel: boolean } | undefined {
        const activityOptions = get(this.privateSettings, 'activitiesOptions', {});
        return activityOptions[typology] || undefined;
    }

    public activitiesWithDefinedTypology(): TypologyLabel[] {
        return get(this.privateSettings, 'activitiesWithDefinedTypology', []);
    }
}
