import {TypologyLabel} from '@modules/activities/core/typologies/typology.label';
import {takeUntil, tap} from 'rxjs/operators';
import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {CommunicationCenterService} from '@modules/communication-center';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from 'app/settings';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {LRSTracking} from '../../../../../settings';
import {TranslateService} from '@ngx-translate/core';
import {BaseActivityComponent} from '../base-activity.component';
import {ActivitiesService} from '../../activities.service';
import {LessonsService} from '../../lessons/services/lessons.service';
import {Observable, of} from 'rxjs';
import {LessonNavigationService} from '../../lesson-navigation.service';
import {FeedbackComponent} from '@modules/activities/core/player-components/feedback/feedback.component';
import {ContextualService} from '@modules/activities/core/services/contextual.service';
import {ExternalActivityGranule} from '@modules/activities/core/models/activities/typologies/external-activity.granule';
import {environment} from '../../../../../../environments/environment';
import {answerStatusEnum} from '@modules/activities/core/models';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';

declare var $: any;
declare var Channel: any;
declare var TaskProxyManager: any;
declare var Platform: any;

// TODO pas de ca ici
const settingsStructure = new ModelSchema({
    iframeParams: Structures.array(['uid']),
    dynamicContent: Structures.boolean(false),
    helpButton: Structures.object({}),
    APIPEMTask: Structures.boolean(false),
    addExitButton: Structures.boolean(false),
});

@Component({
    selector: 'app-external',
    templateUrl: './external.component.html'
})
export class ExternalComponent extends BaseActivityComponent<ExternalActivityGranule> implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('iframeViewport', {static: true}) iframeViewport: ElementRef;
    @ViewChild('iframeView') iframeView: ElementRef;

    public iframeUrl: SafeResourceUrl;
    public currentUser: DataEntity;
    public settings: { [key: string]: any };
    private isFullscreen = false;
    private LRSTracking = LRSTracking;
    private url: URL;
    private previousIdAssignmentNextEvent: number | string = 0;
    private grade: number;
    private answer = '';
    public platform: any;
    public sizeinitDone = false;
    private errorsCount = 0;

    private isInitializing: boolean;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected lessonsService: LessonsService,
        private changeDetector: ChangeDetectorRef,
        private octopusConnect: OctopusConnectService,
        protected communicationCenter: CommunicationCenterService,
        protected contextualService: ContextualService,
        private sanitizer: DomSanitizer,
        private router: Router,
        private dialog: MatDialog,
        private translate: TranslateService,
        protected lessonNavigationService: LessonNavigationService,
        private config: LessonsConfigurationService
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, contextualService, lessonNavigationService);
        window.addEventListener('message', this.receiveMessage, false);
        this.settings = settingsStructure.filterModel(modulesSettings.activities);
        if (this.config.hideTopBarForSpecificActivitiesTypes().includes(TypologyLabel.external)) {
            this.communicationCenter.getRoom('header-exo').next('show', false);
        }
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.currentUser = data;
                }
            });

        this.octopusConnect.listen('externalAction').pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe((action: DataEntity) => {
            if (action.get('action') === 'next') {
                this.launchNextActivity();
            }
        }, error => {
            this.errorApi();
        });

        this.communicationCenter.getRoom('feedback').getSubject('nextActivity').subscribe(next => {
            if (next) {
                this.launchNextActivity();
            }
        }, error => {
            this.errorApi();
        });

        this.communicationCenter.getRoom('feedback').getSubject('needHelp').subscribe(next => {
            if (next) {
                this.openHelp();
            }
        }, error => {
            this.errorApi();
        });

        this.octopusConnect.listen('feedbackMessage').pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe((message: DataEntity) => {
            if (message.get('type') === 'disclaimer') {
                this.openDetail(message);
            }
        }, error => {
            this.errorApi();
        });
    }

    ngOnInit(): void {
        super.ngOnInit();
        // close sidebar panel when start activity
        this.communicationCenter
            .getRoom('verticalSidebar')
            .getSubject('openOrFoldSidebar')
            .next(false);
    }

    receiveMessage = (event) => {
        // Vous pouvez ajouter des vérifications d'origine pour plus de sécurité
        if (event.data === 'goBack' || event.data?.action === 'goBack') {
            this.communicationCenter.getRoom('activities')
                .getSubject('exitLesson')
                .next(true);
        }
        if (event.data === 'storyLine completed') {
           if (event.origin+'/' === environment.baseApiUrl()) {
                if (!this.userSave || this.userSave.get('state') !== 'validated') {
                    this.save(answerStatusEnum.correct).subscribe();
                }
           }
        }
        if (event.data === 'storyLine next') {
            if (event.origin+'/' === environment.baseApiUrl()) {
                this.next();
            }
        }
    };

    ngAfterViewInit(): void {
        this.iframeSizeByRatio();
    }

    ngOnDestroy(): void {
        if (!this.isActivityEmbedded) {
            this.communicationCenter.getRoom('header-exo').next('show', true);
        }
        super.ngOnDestroy();
        window.removeEventListener('message', this.receiveMessage);
        this.communicationCenter
            .getRoom('verticalSidebar')
            .getSubject('openOrFoldSidebar')
            .next(true);
    }

    /**
     * open modal with message when error from API
     * @private
     */
    private errorApi(): void {
        const dialogRef = this.dialog.open(FeedbackComponent, {data: {btnTitle: 'generic.ok', title: 'generic.error_server_title', content: 'generic.error_server'}});
        dialogRef.afterClosed().pipe(tap(() => this.initialize())).subscribe();
    }

    public openDetail(message: DataEntity): void {
        if (message.get('detail')) {
            const config = {
                titleDialog: '',
                bodyDialog: message.get('detail'),
                labelTrueDialog: 'OK',
                labelOtherDialog: ''
            };

            if (message.get('type') === 'disclaimer') {
                this.translate.get('generic.start').subscribe((translation: string) => {
                    config.labelTrueDialog = translation;
                });

                const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                    panelClass: 'feedback-earning-dialog',
                    data: config
                });

                dialogRef.afterClosed().subscribe(_result => {
                    this.activitiesService.currentAssignment.set('hideFeedbacks', false);
                    this.launchNextActivity();
                });
            }
        }
    }

    public openHelp(): void {
        if (this.settings.helpButton.data && this.url) {
            this.settings.helpButton.data.payload.sensors.idTask = this.url.searchParams.get('idTask');
            this.settings.helpButton.data.payload.sensors.idLearner = this.url.searchParams.get('uid');
            this.settings.helpButton.data.payload.uid = this.url.searchParams.get('uid');
            this.settings.helpButton.data.assId = this.activitiesService.currentAssignment.id;
            this.octopusConnect.createEntity('feedbacks', this.settings.helpButton.data);
        }
    }

    @HostListener('fullscreenchange', ['$event'])
    @HostListener('webkitfullscreenchange', ['$event'])
    @HostListener('mozfullscreenchange', ['$event'])
    @HostListener('MSFullscreenChange', ['$event'])
    screenChange(_event): void {
        this.isFullscreen = !this.isFullscreen;
        // setTimeout(() => {
        this.iframeSizeByRatio();
        // }, 1000);
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.iframeSizeByRatio();
    }

    public onIframeLoad(): void {
        if (this.settings.APIPEMTask && !this.platform) {
            TaskProxyManager.getTaskProxy('taskIframe', (task) => {

                this.platform = new Platform(task);
                TaskProxyManager.setPlatform(task, this.platform);
                task.getMetaData((metaData) => {
                    this.platformLoad(task, this.platform, metaData);
                });
            }, true);
        }

    }

    /**
     * load next recommendation (which is stored in userSave)
     */
    public launchNextActivity(): void {
        if (!this.isActivityEmbedded) {
            this.loadUserSave();
        }
        this.communicationCenter.getRoom('feedback').next('reset', true);
    }

    /**
     * Control the display of next button for this activity type
     */
    public displayUserActionButton(): boolean {
        return false;
    }

    protected setContentData(activityAttributes): void {
        if (activityAttributes) {
            this.activityType = activityAttributes.metadatas.typology.label;
            this.referenceActivityGranule = activityAttributes.reference;
            if (!this.isActivityEmbedded) {
                this.loadUserSave();
            } else {
                this.initialiseIframeUrl();
            }
            if (this.isLessonContainOnlyActivityExternal() || this.isActivityEmbedded) {
                super.addExceptionsForButtonsFromActivity([
                    {
                        type: 'next',
                        display: false,
                        options: {
                            EXT: {
                                display: {
                                    case: 'force',
                                    value: false
                                }
                            },
                        }
                    }
                ]);
            }
        }
    }

    /**
     * Load userSave which store the iframe url and add params depending on instance settings or redirect to the end of the curent lesson
     */
    protected loadUserSave(): void {
        this.activitiesService.getUserSave(this.activity.id, this.assignmentId).pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(userSave => {
                if (!userSave && this.lessonsService.isMyAssignment()) {
                    this.save().subscribe();
                } else {
                    this.userSave = userSave;
                }

                if (this.activitiesService.settings.setAnswerWithUserSave) {
                    this.setAnswer();
                }
                // we not send another next if it was already done for the same id assignment
                if (this.activitiesService.currentAssignment
                    && (this.previousIdAssignmentNextEvent === 0 || +this.previousIdAssignmentNextEvent !== +this.activitiesService.currentAssignment.id)) {
                    this.previousIdAssignmentNextEvent = this.activitiesService.currentAssignment.id;
                    this.communicationCenter
                        .getRoom('assignation')
                        .next('event', {id: this.activitiesService.currentAssignment.id, event: 'next'});
                }

                if (!this.isActivityEmbedded) {
                    this.initialiseIframeUrl();
                }
            });
    }

    /**
     * Resets the current component
     */
    protected reset(): Observable<boolean> {
        this.isInitializing = true;
        delete this.referenceActivityGranule;
        delete this.iframeUrl;
        delete this.platform;
        delete this.wording;
        this.answer = null;
        this.userSave = null;
        this.changeDetector.detectChanges();
        return super.reset();
    }

    /**
     * resize the image according to its frame/viewport (in reality it's container iframe will take the same size automatically)
     */
    private iframeSizeByRatio(): void {
        if (this.iframeView && !this.isFullscreen) {
            const iframe = this.iframeView.nativeElement;
            iframe.height = '';
            iframe.width = '';
            iframe.height = +this.iframeViewport.nativeElement.offsetHeight;
            iframe.width = +this.iframeViewport.nativeElement.offsetWidth;
            this.sizeinitDone = true;
        }
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<any> {
        return of(this.answer);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        if (this.userSave) {
            // TODO UTILISER VARIABLE answersSelected
            this.answer = this.userSave.get('userActivity').entitySave.content;
        }
    }

    protected getGrade(): { oldGrade: number, newGrade: number } {
        // todo: implements old grade
        return {oldGrade: 0, newGrade: this.grade};
    }

    private platformLoad(task, platform, metaData): void {
        if (this.url.searchParams.get('randomSeed') === '1') {
            this.platform.getTaskParams = (key, defaultValue, complete, error) => {
                let res = {minScore: -3, maxScore: 10, randomSeed: this.currentUser.id.toString(), noScore: 0, readOnly: false, options: {}};
                if (key) {
                    if (key !== 'options' && key in res) {
                        res = res[key];
                    } else if (res.options && key in res.options) {
                        res = res.options[key];
                    } else {
                        res = (typeof defaultValue !== 'undefined') ? defaultValue : null;
                    }
                }
                complete(res);
            };
        }

        this.platform.updateDisplay = (data, success, error) => {
            success();
        };

        this.platform.openUrl = (sTextId, success, error) => {
            success();
        };
        this.platform.validate = (mode, success, error) => {
            if (mode === 'cancel') {
                console.warn('cancel');
                return;
            } else if (mode === 'nextImmediate') {
                console.warn(mode);
            } else if (mode === 'log') {
                this.gradeCurrentAnswerLog(task, success, error, mode);
            } else {
                this.gradeCurrentAnswer(task, success, error, mode);
            }
        };

        const loadedViews = {'task': true, 'solution': true, 'hints': true, 'editor': true, 'grader': true, 'metadata': true, 'submission': true};
        task.load(loadedViews, () => {
            window.setTimeout(() => {
                if (this.answer != null) {
                    task.reloadAnswer(this.answer, () => {
                    });
                }
            }, 500);
        });
    }

    private gradeCurrentAnswer(task, success, error, mode): void {
        task.getAnswer((answer) => {
            task.gradeAnswer(answer, null, (score, message) => {
                if (mode !== 'stay') {
                    task.getState((callback) => {
                        this.errorsCount = JSON.parse(callback).stats.incorrectSubmissionsCount;
                        this.grade = +score;
                        this.answer = answer;
                        this.doAction('showActivityRewards', ['save']);
                    }, (callbackError) => {
                        console.log('getStateWithGradeError', callbackError);
                    });
                }
            }, error);
        }, error);

    }

    /**
     * not use gradeAnswer when we are in log mode because fire error
     */
    private gradeCurrentAnswerLog(task, success, error, mode): void {
        task.getAnswer((answer) => {
            task.getState((callback) => {
                this.errorsCount = JSON.parse(callback).stats.incorrectSubmissionsCount;
                this.answer = answer;
                this.doAction('save', ['save']);
            }, (callbackError) => {
                console.log('getStateError', callbackError);
            });
        }, error);
    }

    /**
     * navigate to previous place if user confirm
     */
    public navigateToLessons(): void {
        const config = {
            titleDialog: '',
            bodyDialog: '',
            labelTrueDialog: '',
            labelFalseDialog: ''
        };


        this.translate.get('generic.yes').subscribe((translation: string) => {
            config.labelTrueDialog = translation;
        });

        this.translate.get('generic.no').subscribe((translation: string) => {
            config.labelFalseDialog = translation;
        });

        this.translate.get('activities.iframe_title').subscribe((translation: string) => {
            config.titleDialog = translation;
        });
        this.translate.get('activities.iframe_body').subscribe((translation: string) => {
            config.bodyDialog = translation;
        });

        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            panelClass: 'iframe-confirm',
            data: config
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.communicationCenter
                    .getRoom('activities')
                    .next('backToLesson', {forceUrl: null, initialiseSubject: true});
            }
        });

    }

    /**
     * is displaying return to lesson button
     */
    public displayReturnButton(): boolean {
        return this.settings.addExitButton;
    }

    protected getAttempts(): number {
        return this.errorsCount ? this.errorsCount : 0;
    }


    protected validate(): void {
        throw new Error('Method not implemented.');
    }

    /**
     * savoir si la lesson en cours ou sous-lesson ne contiens qu'une activité (en l'occurance de typo External)
     * @private
     */
    private isLessonContainOnlyActivityExternal(): boolean {
        return this.lessonsService.subLessonContentEdited?.length === 1
            || this.lessonsService.activities?.length === 1;
    }

    private initialiseIframeUrl() {
        let tempUrl = this.referenceActivityGranule.instruction;
        if (this.settings.dynamicContent && this.userSave && this.userSave.get('userActivity') && this.userSave.get('userActivity').entitySave.content[0]) {
            tempUrl = this.userSave.get('userActivity').entitySave.content[0];
        }

        if (tempUrl === 'end') {
            if (this.activitiesService.currentAssignment &&
                this.activitiesService.currentAssignment.get('type_term') &&
                this.activitiesService.currentAssignment.get('type_term').label === 'init') {
                this.activitiesService.metacognition(true);
            } else {
                this.router.navigate(['../../recap'], {relativeTo: this.activatedRoute});
            }
        }

        if (tempUrl) {
            this.url = new URL(tempUrl);
            if (this.settings.iframeParams.includes('uid')) {
                this.url.searchParams.set('uid', this.currentUser.id.toString());
            }
            if (this.settings.iframeParams.includes('prenom')) {
                this.url.searchParams.set('prenom', this.currentUser.get('label'));
            }
            if (this.settings.iframeParams.includes('codeclasse')) {
                this.url.searchParams.set('codeclasse', this.currentUser.get('groups')[0]);
            }
            if (this.settings.iframeParams.includes('token')) {
                this.url.searchParams.set('token', JSON.parse(localStorage.getItem('http_accessToken')));
            }
            if (this.settings.iframeParams.includes('assignationId') && this.activitiesService.currentAssignment) {
                this.url.searchParams.set('assignation_id', this.activitiesService.currentAssignment.id.toString());
            }
            if (this.settings.iframeParams.includes('assignatedUser') && this.activitiesService.currentAssignment) {
                if (this.activitiesService.currentAssignment &&
                    this.activitiesService.currentAssignment.attributes['assignated_user'] &&
                    this.activitiesService.currentAssignment.attributes['assignated_user'].uid
                ) {
                    this.url.searchParams.set('assignatedUser', this.activitiesService.currentAssignment.attributes['assignated_user'].uid.toString());
                }
            }
            if (this.settings.iframeParams.includes('idLesson') && this.activitiesService.currentAssignment) {
                this.url.searchParams.set('idLesson', this.activitiesService.currentAssignment.attributes['assignated_node'].id.toString());
            }
            if (this.settings.iframeParams.includes('idTask')) {
                this.url.searchParams.set('idTask', Date.now().toString());
            }
            this.url.searchParams.set('channelId', this.currentUser.id.toString());


            const traceInfos = {
                uid: this.url.searchParams.get('uid'),
                idTask: this.url.searchParams.get('idTask')
            };

            if (this.LRSTracking === true) {
                this.communicationCenter
                    .getRoom('traces')
                    .next('infos', traceInfos);
            }

            this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url.href + '&log=1'); // TODO chargée deux fois
            this.wording = 'this.referenceActivityGranule.wording';
            this.wordingAudio = 'this.referenceActivityGranule.wordingAudio';
            this.isInitializing = false;
            if (this.lessonsService.currentAssignment || this.activitiesService.currentAssignment) {
                // todo utiliser une seule reference pour le stockage de l'assignation en cours
                this.communicationCenter
                    .getRoom('assignation')
                    .next('event', {id: this.lessonsService.currentAssignment.id, event: 'loaded'});
            }
        }
    }
}
