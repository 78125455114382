import {Component, inject, Input, OnInit} from '@angular/core';
import {ActivityGranule, ActivityMetaEntityAttributes, AnswersContent} from '@modules/activities/core/models';
import {TypologyLabel} from '@modules/activities/core/typologies/typology.label';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject, shareReplay} from 'rxjs';
import {distinctUntilChanged, map, mergeMap, take} from 'rxjs/operators';
import {usingLoading} from 'shared/utils/rxjs';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';
import {ShortAnswerActivityGranule} from '@modules/activities/core/models/activities/typologies/short-answer-activity.granule';
import {ActivityRepositoryService} from "@modules/activities/core/services/activity-repository.service";

const ACTIVITY_NAME_LIMIT = 65;
const ACTIVITY_NAME_ENDING = '...';

@Component({
    selector: 'app-pretty-activity-card',
    templateUrl: './pretty-activity-card.component.html'
})
export class PrettyActivityCardComponent implements OnInit {

    @Input() target!: { id: string | number, title?: string };

    public activityRepositoryService = inject(ActivityRepositoryService);

    public loading$ = new ReplaySubject<boolean>(1);
    public referenceId$ = new ReplaySubject<string | number>();

    private activityValid$ = new BehaviorSubject<boolean>(null);

    public activity$ =
        this.referenceId$.pipe(
            mergeMap(activityId => this.activityRepositoryService.getActivity(activityId)),
            usingLoading((isLoading) => this.loading$.next(isLoading)),
            take(1),
            shareReplay(1),
        );

    public metadatas$ = this.activity$.pipe(
        map(activity => activity.getEmbed<ActivityMetaEntityAttributes>('metadatas') as TypedDataEntityInterface<ActivityMetaEntityAttributes>)
    );

    public activityReference$ = this.activity$.pipe(
        map(activity => activity.get('reference'))
    );

    public typology$ = this.metadatas$.pipe(
        map(metadatas => metadatas.get('typology'))
    );

    public wording$ = this.activityReference$.pipe(
        map(reference => reference.wording)
    );

    public instruction$ = this.activityReference$.pipe(
        map(reference => reference.instruction)
    );

    public activityContent$ = this.activityReference$.pipe(
        map(reference => reference.activity_content)
    );

    public activityName$ = combineLatest([
        this.wording$, this.instruction$
    ]).pipe(
        map((strDescription) => {
                const raw = strDescription.map(s => (s ?? '').replace(/<[^>]*>/g, ''))
                    .join(' ');

                return raw.length > ACTIVITY_NAME_LIMIT ? raw.substring(0, ACTIVITY_NAME_LIMIT - ACTIVITY_NAME_ENDING.length) + ACTIVITY_NAME_ENDING : raw;
            }
        ),
    );

    ngOnInit(): void {
        this.referenceId$.next(this.target.id);
        this.validateActivity();
        this.typology$.pipe(take(1)).subscribe(); // les async sont caché dans un ngIf, il faut trouver un moyen de les déclencher
    }

    public getUserFriendlyTypologyLabel(
        typology: { id: string; label: TypologyLabel },
        rawActivity: ActivityGranule
    ): string {
        let label: string = typology?.label;

        if (label === TypologyLabel.shortAnswer) {
            const shortAnswerActivity = rawActivity as ShortAnswerActivityGranule;
            if (shortAnswerActivity.attributes.reference.config?.longAnswer) {
                label = 'long_answer';
            }
        }

        return 'pretty_activity.typology_' + label + '_label';
    }

    private getTypologyLabel(typology: { id: string; label: TypologyLabel }): string {
        return typology?.label;
    }

    private validateActivity(): void {
        combineLatest([this.typology$, this.activityContent$, this.activity$]).pipe(
            take(1),
            map(([typology, activityContent, activity]) => {
                if (!typology) {
                    console.error("Error typology with granule : " + activity.id);
                    return false;
                }
                if (typology && this.getTypologyLabel(typology) === TypologyLabel.fillBlanks) {
                    const fillBlanksContent = activityContent as AnswersContent;
                    const blanksCount = (fillBlanksContent.answer_text.match(/\[\]/g) || []).length;
                    if (blanksCount !== fillBlanksContent.answers.length) {
                        console.error("Error number of blanks with fillBlank granule : " + activity.id);
                        return false;
                    }
                }
                return true;
            })
        ).subscribe(result => this.activityValid$.next(result));
    }

    public isActivityValid(): Observable<boolean> {
        return this.activityValid$.pipe(
            distinctUntilChanged()
        );
    }

}
